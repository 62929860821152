import React from "react";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";

class TermsConditionsPage extends React.Component {
  static contextType = AuthContext;

  render() {
    const { userEmail, signOut } = this.context;

    return (
      <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          Terms and Conditions
        </h2>
        <p>Last updated: July 8, 2023</p>

        <p>
          These Terms and Conditions ("Terms", "Terms and Conditions") govern
          your relationship with the GameAway cloud gaming platform (the
          "Service") operated by GameAway ("us", "we", or "our").
        </p>

        <p>
          Please read these Terms and Conditions carefully before using the
          Service.
        </p>

        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          The Service and its original content, features and functionality are
          and will remain the exclusive property of GameAway and its licensors.
          The Service is protected by copyright, trademark, and other laws of
          both the India and foreign countries. Our trademarks and trade dress
          may not be used in connection with any product or service without the
          prior written consent of GameAway.
        </p>

        <h2>Use of Service</h2>
        <p>
          The cloud PCs provided as part of the Service are solely intended to
          be used for playing the games installed on them. Downloading or
          installing any software on our cloud machines without our consent is
          against these terms and can result in a permanent ban. Mods and other
          software can only be installed by taking consent from us first.
        </p>

        <h2>Changes to These Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>

        <h2>Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us:</p>
        <p>
          <a href="mailto:support@gameaway.in">By email: support@gameaway.in</a>
          ,
          <br />
          <a
            href="https://discord.gg/enfJGFcBrA"
            target="_blank"
            rel="noreferrer"
          >
            On Discord
          </a>
          ,
          <br />
          <a
            href="https://t.me/+O2N-1qn2_vxhNDY9"
            target="_blank"
            rel="noreferrer"
          >
            On Telegram
          </a>
          .
        </p>
        <Footer />
      </div>
    );
  }
}

export default TermsConditionsPage;
