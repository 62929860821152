export const gameList = [
  {
    name: "Apex Legends",
    store: "Steam",
    image: "apex_legends",
    free: true,
  },
  {
    name: "Asphalt Legends Unite",
    store: "Steam",
    free: true,
  },
  {
    name: "Counter Strike 2",
    store: "Steam",
    free: true,
  },
  {
    name: "eFootball",
    store: "Steam",
    free: true,
  },
  {
    name: "Euro Truck Simulator 2",
    store: "Steam",
  },
  {
    name: "Genshin Impact",
    store: "HoYoVerse",
    free: true,
  },
  {
    name: "FC 24",
    store: "Steam",
  },
  {
    name: "FC 25",
    store: "Steam",
  },
  {
    name: "Forza Horizon 4",
    store: "Steam",
  },
  {
    name: "God of War",
    store: "Steam",
  },
  {
    name: "Grand Theft Auto V Steam",
    store: "Steam",
    hourlyPlanOnly: true,
  },
  {
    name: "Grand Theft Auto V",
    store: "Epic",
    hourlyPlanOnly: true,
  },
  {
    name: "Need for Speed Unbound",
    store: "Steam",
  },
  {
    name: "Red Dead Redemption 2",
    store: "Steam",
  },
  {
    name: "Rocket League",
    store: "Epic ", // keep the space after Epic otherwise it'll try to boot Rocket League - Epic instead
    free: true,
  },
  {
    name: "Spider-Man Remastered",
    store: "Steam",
  },
  {
    name: "Spider-Man Miles Morales",
    store: "Steam",
  },
];
