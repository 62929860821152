import { useState, useEffect } from "react";
import React from "react";
import clsx from "classnames";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
export default function UserPage() {
  // pass useState() the initial value

  // machineType can be "ondemand", "100gb", "200gb", "1tb"
  const [dataLoaded, setDataLoaded] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [vmSharingUsers, setVmSharingUsers] = useState([]);
  const [vmSharingUsersLoaded, setVmSharingUsersLoaded] = useState(false);
  const [manageUrl, setManageUrl] = useState(null);
  const [sharingUser, setSharingUser] = useState(null);
  const { t } = useTranslation();

  const { user, signOut, signIn } = React.useContext(AuthContext);

  const fetchSharingUsers = async () => {
    try {
      const response = await fetch(
        "https://lskx5chc5a.execute-api.ap-south-1.amazonaws.com/default/getVmSharingUsers",
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // Assuming the API returns an object with subscriptions and payments arrays
      setVmSharingUsers(data);
      setVmSharingUsersLoaded(true); // Update state to indicate data is loaded
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Handle errors here, such as setting an error state
    }
  };

  useEffect(() => {
    if (!user) {
      console.log("User signInSession is null, waiting...");
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://h9e11lic8c.execute-api.ap-south-1.amazonaws.com/prod/",
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: user.signInUserSession.idToken.jwtToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Assuming the API returns an object with subscriptions and payments arrays
        setSubscriptions(data.subscriptions);
        setPayments(data.payments);
        setManageUrl(data.manage_url);
        setDataLoaded(true); // Update state to indicate data is loaded
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
        // Handle errors here, such as setting an error state
      }
    };

    // Call the function
    fetchData();
    fetchSharingUsers();
  }, [user]); // The empty array causes this effect to only run once on mount

  const addSharingUser = async () => {
    try {
      setVmSharingUsersLoaded(false);
      const response = await fetch(
        "https://chbie1psj3.execute-api.ap-south-1.amazonaws.com/default/addVmSharingUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({
            sharingUsername: sharingUser,
          }),
        }
      );
      fetchSharingUsers();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Handle errors here, such as setting an error state
    }
  };

  const removeSharingUser = async (userIdToRemove) => {
    try {
      setVmSharingUsersLoaded(false);
      const response = await fetch(
        "https://v7azeu02u2.execute-api.ap-south-1.amazonaws.com/default/removeVmSharingUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({
            sharingUsername: userIdToRemove,
          }),
        }
      );
      fetchSharingUsers();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Handle errors here, such as setting an error state
    }
  };

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <div className="relative flex flex-col mx-auto w-full justify-center items-left gap-6 overflow-x-auto pb-6">
        <div className="relative flex flex-col mx-auto w-full justify-center items-left gap-6 overflow-x-auto">
          <div className="text-lg font-bold">Username: {user.username}</div>
          <div className="text-lg font-bold">
            Email: {user.attributes?.email}
          </div>
          <div className="text-lg font-bold">Virtual Gaming PC Sharing</div>
          <div className="text-md">{t("add_vm_sharing_users")}</div>
          <div
            className={clsx("skeleton w-full h-32", {
              hidden: vmSharingUsersLoaded,
            })}
          ></div>
          <div
            className={clsx("overflow-x-auto w-full", {
              hidden: !vmSharingUsersLoaded,
            })}
          >
            <table className="table table-zebra table-xs md:table-md">
              {/* head */}
              <thead>
                <tr>
                  <th className="truncate">User's Email</th>
                  <th className="truncate">Virtual Gaming PC Access</th>
                </tr>
              </thead>
              <tbody>
                {vmSharingUsers.map((vmSharingUser) => (
                  <tr key={vmSharingUser.username}>
                    <td>{vmSharingUser.username}</td>
                    <td>
                      <button
                        class="btn btn-outline btn-error"
                        disabled={!vmSharingUsersLoaded}
                        onClick={() =>
                          removeSharingUser(vmSharingUser.username)
                        }
                      >
                        Revoke Access
                      </button>
                    </td>
                  </tr>
                ))}
                <tr key="finalrow">
                  <td>
                    <input
                      type="text"
                      placeholder="Email to share with"
                      class="input input-bordered max-w-xs"
                      value={sharingUser}
                      onChange={(e) => setSharingUser(e.target.value)}
                    />
                  </td>
                  <td>
                    <button
                      class="btn btn-outline"
                      disabled={!vmSharingUsersLoaded}
                      onClick={addSharingUser}
                    >
                      Give Access
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-lg font-bold">Subscriptions</div>
        <div
          className={clsx("skeleton w-full h-32", {
            hidden: dataLoaded,
          })}
        ></div>
        <div
          className={clsx("overflow-x-auto w-full", { hidden: !dataLoaded })}
        >
          <table className="table table-zebra table-xs md:table-md">
            <thead>
              <tr className="w-full">
                <th className="truncate">Name</th>
                <th className="truncate">Quantity</th>
                <th className="truncate">Amount</th>
                <th className="truncate">Next Payment</th>
                <th className="truncate">Manage</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions
                .filter((sub) => sub.status === "active")
                .map((sub) => (
                  <tr key={sub.id} className="w-full">
                    <td>
                      {
                        sub.payment_settings.payment_method_options.card
                          .mandate_options?.description
                      }
                    </td>
                    {/* Product Name */}
                    <td>{sub.items.data[0].quantity}</td> {/* Quantity */}
                    <td>
                      ₹
                      {(
                        sub.payment_settings.payment_method_options.card
                          .mandate_options?.amount / 100
                      ).toFixed(2)}
                    </td>
                    {/* Amount */}
                    <td>
                      {new Date(
                        sub.current_period_end * 1000
                      ).toLocaleDateString()}
                    </td>
                    {/* Next Payment Date */}
                    <td>
                      <a className="underline" target="_blank" href={manageUrl}>
                        Manage Subscription
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="text-lg font-bold">Payments</div>
        <div
          className={clsx("skeleton w-full h-32", {
            hidden: dataLoaded,
          })}
        ></div>
        <div
          className={clsx("overflow-x-auto w-full", { hidden: !dataLoaded })}
        >
          <table className="table table-zebra table-xs md:table-md">
            {/* head */}
            <thead>
              <tr>
                <th className="truncate">Product Name</th>
                <th className="truncate">Quantity</th>
                <th className="truncate">Amount</th>
                <th className="truncate">Date</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.lines.data[0]?.description}</td>
                  <td>{payment.lines.data[0]?.quantity || "N/A"}</td>
                  <td>₹{(payment.amount_paid / 100).toFixed(2)}</td>
                  <td>
                    {new Date(payment.created * 1000).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
}
